import { Ds4FieldName } from '../../../../types/ATNTypes'

export const ds4FieldToTextFieldKey: Record<Ds4FieldName, string> = {
  emailadressen: 'address',
  telefonnummern: 'number',
  versicherter_email: 'address',
  versicherter_telefon: 'number',
}

export const emailDropdownOptions = [
  { label: 'Keine Anmerkung', value: '' },
  { label: 'Privat', value: 'Privat' },
  { label: 'Arbeit', value: 'Arbeit' },
]

export const phoneDropdownOptions = [
  { label: 'Keine Anmerkung', value: '' },
  { label: 'Mobil', value: 'Mobil' },
  { label: 'Privat', value: 'Privat' },
  { label: 'Arbeit', value: 'Arbeit' },
  { label: 'Festnetz', value: 'Festnetz' },
]

export const dropdownOptions = {
  telefonnummern: phoneDropdownOptions,
  emailadressen: emailDropdownOptions,
  versicherter_telefon: phoneDropdownOptions,
  versicherter_email: emailDropdownOptions,
} as const

export const MAXLENGTHMAPPER = {
  telefonnummern: 20,
  emailadressen: 20,
  versicherter_telefon: 1,
  versicherter_email: 1,
  default: 1,
} as const
