import { v4 as uuidv4 } from 'uuid'

import { dropdownOptions, MAXLENGTHMAPPER } from './constants'
import { AnyObject, DropdownKeys, FieldName } from './types'

export const getNestedValue = (obj: AnyObject, path: string): unknown =>
  path.split('.').reduce((acc, part) => acc && acc[part], obj)

export const addEmptyField = (contactFieldKey: string) => {
  const newKey = uuidv4()
  return { id: newKey, label: '', [contactFieldKey]: '' }
}

export const getDropdownItems = (fieldName?: FieldName) => {
  if (!fieldName || !(fieldName in dropdownOptions)) {
    return [{ label: 'Keine Anmerkung', value: '' }]
  }
  return dropdownOptions[fieldName as DropdownKeys]
}

export const getMaxLength = (fieldName?: FieldName) => {
  return MAXLENGTHMAPPER[fieldName ?? 'default']
}

export const getInputWidth = (size?: 's' | 'm' | 'l') => {
  switch (size) {
    case 'm':
      return '50%'
    case 'l':
      return '100%'
    default:
      return '33%'
  }
}
