import { useFormikContext, FastField, FieldProps } from 'formik'
import { useContext, useMemo } from 'react'
import { getPatient } from '../../../../contexts/PatientContext'
import { PracticeContext } from '../../../../contexts/PracticeContext'
import { TextInputType } from '../../../../types/ATNTypes'
import { InputContainer, InputField } from '../../../FormElement'
import { replaceContent, renderInputLabel, validateInput } from '../helpers'
import React from 'react'
import { getInputWidth } from '../TextInputGroup/helpers'

interface TextInputProps {
  element: TextInputType
  activeLang: string
}
const TextInput = ({ activeLang, element }: TextInputProps) => {
  const patient = getPatient(useFormikContext().values)
  const practice = useContext(PracticeContext)

  const inputWidth = useMemo(
    () => getInputWidth(element.style?.size as 's' | 'm' | 'l'),
    [element.style],
  )

  const placeholder = useMemo(() => {
    if (element.placeholder && element.placeholder[activeLang]) {
      return replaceContent(
        element.placeholder[activeLang],
        activeLang,
        patient,
        practice,
      )
    }
    return ''
  }, [element.placeholder, activeLang, patient, practice])

  return (
    <InputContainer id={element.id}>
      {renderInputLabel(element, activeLang)}
      <FastField
        key={`${element.id}-${activeLang}`}
        name={element.id}
        validate={(value: string) => validateInput(value, element, activeLang)}>
        {({ field, meta }: FieldProps) => (
          <InputField
            type="text"
            name={element.id}
            variant="outlined"
            placeholder={placeholder}
            error={meta.touched && meta.error ? true : false}
            helperText={(meta.touched && meta.error) || ''}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value || ''}
            autoComplete="off"
            autoSave="off"
            autoCorrect="off"
            style={{
              width: inputWidth,
            }}
          />
        )}
      </FastField>
    </InputContainer>
  )
}

export default TextInput
