import React, { useMemo } from 'react'
import { FieldArray, useFormikContext, FastField, FieldProps } from 'formik'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import InputWithDropdown from '../../../InputWithDropdown'
import { InputField } from '../../../FormElement'
import { renderInputLabel, validateInput } from '../helpers'
import {
  FieldContainer,
  IconContainer,
  LabelAndDeleteIconContainer,
} from './styles'
import {
  addEmptyField,
  getDropdownItems,
  getInputWidth,
  getMaxLength,
  getNestedValue,
} from './helpers'
import { ds4FieldToTextFieldKey } from './constants'
import { AnyObject, FormValues, TextInputGroupProps } from './types'

const TextInputGroup = ({ element, activeLang }: TextInputGroupProps) => {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const inputWidth = useMemo(
    () => getInputWidth(element.style?.size as 's' | 'm' | 'l'),
    [element.style],
  )

  const fieldName = element.ds4FieldName
  const maxLength = useMemo(() => getMaxLength(fieldName), [fieldName])
  const dropdownItems = useMemo(() => getDropdownItems(fieldName), [fieldName])
  const contactFieldKey = fieldName ? ds4FieldToTextFieldKey[fieldName] : ''

  let currentValues: object[]

  const fieldData = getNestedValue(values, element.id) as AnyObject[]

  if (fieldData && fieldData.length) {
    currentValues = fieldData
  } else {
    setFieldValue(element.id, [addEmptyField(contactFieldKey)])
  }

  return (
    <div
      style={{
        width: inputWidth,
      }}>
      <FieldArray name={element.id}>
        {({ push, remove }) => (
          <div>
            {currentValues?.map((item: any, index: number) => (
              <>
                <LabelAndDeleteIconContainer>
                  <div>
                    {index === 0 && renderInputLabel(element, activeLang)}
                  </div>
                  <DeleteIcon
                    color="primary"
                    onClick={() => remove(index)}
                    style={{ cursor: 'pointer' }}
                  />
                </LabelAndDeleteIconContainer>

                <FieldContainer key={index}>
                  <FastField
                    name={`${element.id}[${index}].${contactFieldKey}`}
                    validate={(value: string) =>
                      validateInput(value, element, activeLang)
                    }>
                    {({ field, meta }: FieldProps) => (
                      <InputField
                        type="text"
                        variant="outlined"
                        autoComplete="off"
                        autoSave="off"
                        autoCorrect="off"
                        className={
                          meta.touched && meta.error ? 'input-error' : ''
                        }
                        hasLabel={true}
                        error={meta.touched && meta.error ? true : false}
                        helperText={(meta.touched && meta.error) || ''}
                        width="100%"
                        {...field}
                      />
                    )}
                  </FastField>
                  <FastField name={`${element.id}[${index}].label`}>
                    {({ field }: FieldProps) => (
                      <>
                        <InputWithDropdown
                          {...field}
                          options={dropdownItems}
                          value={field.value}
                        />
                      </>
                    )}
                  </FastField>
                </FieldContainer>
              </>
            ))}
            {currentValues?.length < maxLength && (
              <IconContainer>
                <AddIcon
                  color="primary"
                  onClick={() => push(addEmptyField(contactFieldKey))}
                />
              </IconContainer>
            )}
          </div>
        )}
      </FieldArray>
    </div>
  )
}

export default TextInputGroup
