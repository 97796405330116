// import { iconBackgroundColor } from '';
import styled from 'styled-components'

export const FieldContainer = styled.div`
  margin-bottom: 25px;
`

export const LabelAndDeleteIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const IconContainer = styled.div`
  height: 50;
  width: 50;
  borderradius: 10;
  justify-content: flex-start;
  align-items: center;
  display: flex;
`
