import React, { useState } from 'react'
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined'

import { DropdownOption, InputWithDropdownProps } from './types'
import {
  DropdownInput,
  DropdownItem,
  DropdownMenu,
  IconAndDropdown,
  InputWithDropdownContainer,
} from './styles'

const InputWithDropdown = ({
  options,
  value,
  // width,
  onChange,
  name,
}: InputWithDropdownProps) => {
  const [showDropdown, setShowDropdown] = useState(false)

  const handleInputChange = (e: any) => {
    onChange(e)
    setShowDropdown(true)
  }

  const handleOptionClick = (option: DropdownOption) => {
    const event = {
      target: {
        name: name,
        value: option.value,
      },
    } as React.ChangeEvent<HTMLInputElement>
    setShowDropdown(false)
    // @ts-expect-error
    onChange(event)
  }

  return (
    <InputWithDropdownContainer>
      <div style={{ flex: 1 }} />
      <IconAndDropdown>
        <LabelOutlinedIcon
          style={{
            marginRight: '6px',
          }}
        />
        <DropdownInput
          name={name}
          type="text"
          value={value}
          onChange={handleInputChange}
          onFocus={() => setShowDropdown(true)}
          onBlur={() => setTimeout(() => setShowDropdown(false), 200)} // Delay to allow click on dropdown items
        />
        {showDropdown && options.length > 0 && (
          <DropdownMenu>
            {options.map((option, index) => (
              <DropdownItem
                key={index}
                onClick={() => handleOptionClick(option)}>
                {option.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        )}
      </IconAndDropdown>
    </InputWithDropdownContainer>
  )
}

export default InputWithDropdown
