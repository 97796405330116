import React from 'react'
import { inRange } from 'lodash'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import { replacePatientContent } from '../../../contexts/PatientContext'
import { replacePracticeContent } from '../../../contexts/PracticeContext'
import { LanguageType, SupportedATNInputType } from '../../../types/ATNTypes'
import { PatientInterface } from '../../../types/Patient'
import { Practice } from '../../../types/Practice'
import Label from '../../../styled/Label'
import RequiredStar from '../../../styled/RequiredStar'

const ATN_KEY_TODAY = 'datum_heute'

export const renderInputLabel = (
  element: SupportedATNInputType,
  activeLang: string,
) =>
  element.label &&
  element.label[activeLang] && (
    <Label>
      {element.label[activeLang]} {element.required && <RequiredStar />}
    </Label>
  )

export const replaceContent = (
  content: string | undefined,
  activeLang: string,
  patient: PatientInterface,
  practice: Practice,
) => {
  content = content || ''
  content = content.replace(`{{${ATN_KEY_TODAY}}}`, moment().format('L'))
  content = replacePatientContent(content, patient, activeLang)
  return replacePracticeContent(content, practice)
}

export const validateInput = (
  value: string | string[],
  element: SupportedATNInputType,
  activeLang: LanguageType,
) => {
  let error
  let message
  let fieldValue = value
  if (element.type === 'textInput' && element.dsWinFieldName === 'pflegegrad') {
    const careLevel = Number(value)
    if (!inRange(careLevel, 0, 6)) {
      return (
        <FormattedMessage
          key={`care-level-input-error-${activeLang}`}
          id="error.careLevelInput"
        />
      )
    }
  }

  if (element.errorMessage) {
    if (element.errorMessage[activeLang]) {
      message = element.errorMessage[activeLang]
    } else {
      if (element.type === 'textInput' || element.type === 'textAreaInput') {
        message = (
          <FormattedMessage
            key={`generic-text-input-error-${activeLang}`}
            id="error.requiredTextInput"
          />
        )
      } else {
        message = (
          <FormattedMessage
            key={`generic-select-input-error-${activeLang}`}
            id="error.requiredSelectInput"
          />
        )
      }
    }
  }

  if (element.required && (!fieldValue || fieldValue.length === 0)) {
    error = message
  }
  return error
}
